import React, { useState } from 'react';
import { theme } from '../../../commons/style/theme';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import {
  Badge,
  Box,
  Button,
  CardMedia,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import LocalizacaoDialog from './LocalizacaoDialog';
import { EnderecoModoEntrega } from './LocalizacaoOpcaoDialog';
import MenuCategories from './MenuCategories';
import { useMediaQuery } from '@material-ui/core';
import { useCart } from 'contexts/cart-context';

const Navbar: React.FC = () => {
  const { total, openCart } = useCart();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.mobile})`);

  const NavContent = () => {
    const isMonitor = useMediaQuery(`(min-width:1441px)`);
    const [dialog, setDialog] = useState<JSX.Element | null>();
    const [enderecoSelecionado, setEnderecoSelecionado] =
      useState<EnderecoModoEntrega>();

    const openLocalizacaoDialog = () => {
      return setDialog(
        <LocalizacaoDialog
          onClose={() => setDialog(null)}
          endereco={{
            rua: enderecoSelecionado?.Rua || '',
            numero: enderecoSelecionado?.Numero || '',
          }}
          onSelectedInfo={(info) => {
            setEnderecoSelecionado(info);
            setDialog(null);
          }}
        />
      );
    };

    return (
      <>
        <Box
          color={'white'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          minWidth={isMonitor ? '64vw' : '84vw'}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={'100%'}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              minWidth={isMonitor ? '64vw' : '84vw'}
              sx={{
                top: 5,
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: 'auto',
                  maxWidth: '200px',
                  height: 'auto',
                  objectFit: 'contain',
                }}
                image={require('static/logos/logoSemCruz.png')}
                alt="Logo"
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="text"
                  onClick={() => openLocalizacaoDialog()}
                  sx={{
                    height: '50px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    padding: '0 20px',
                    textTransform: 'none',
                    border: 'none',
                  }}
                >
                  <LocationOnIcon
                    fontSize="medium"
                    sx={{
                      paddingLeft: 5,
                      color: theme.colors.white,
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: theme.colors.white,
                    }}
                  >
                    Adicione sua localização
                  </Typography>
                </Button>

                <Button onClick={() => openCart()}>
                  <LocalMallIcon
                    fontSize="medium"
                    sx={{ paddingLeft: 5, color: theme.colors.white }}
                  />
                  <Badge
                    badgeContent={total.productQuantity}
                    color="success"
                    max={999}
                    showZero
                    sx={{ pt: 2, pr: 0.2 }}
                  />
                </Button>
              </Box>
            </Box>
            <Divider orientation="horizontal" color="#fffff" sx={{ mt: 1 }} />
            <Box
              display="flex"
              justifyContent="center"
              position={'relative'}
              pt={2}
              width={'100%'}
            >
              <MenuCategories />
            </Box>
            {dialog}
          </Box>
        </Box>
      </>
    );
  };

  const MobileNavBar = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    };
    const DrawerList = (
      <Box
        sx={{ width: 170 }}
        role="presentation"
        onClick={toggleDrawer(false)}
      >
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        pt={2}
        sx={{
          borderRadius: '0 0 10px 10px',
        }}
      >
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon
            fontSize="medium"
            sx={{ paddingRight: 5, color: theme.colors.white }}
          />
        </Button>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        <CardMedia
          component="img"
          sx={{
            width: 'auto',
            maxWidth: '200px',
            height: 'auto',
            objectFit: 'contain',
          }}
          image={require('static/logos/logoBranca.png')}
          alt="Logo"
        />

        <LocalMallIcon
          fontSize="medium"
          sx={{ paddingLeft: 5, color: theme.colors.white }}
        />
        <Badge
          badgeContent={total.productQuantity}
          color="primary"
          max={999}
          showZero
          sx={{ pt: 2, pr: 0.2 }}
        />
      </Box>
    );
  };

  return (
    <header id="nav-menu">
      {!isMobile ? <NavContent /> : <MobileNavBar />}
    </header>
  );
};

export default Navbar;
