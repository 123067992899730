import formatPrice from 'utils/formatPrice';
import CartProducts from './CartProducts';
import { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Alert,
  CircularProgress,
  Badge,
} from '@mui/material';
import { useCart } from 'contexts/cart-context';
import * as S from './style';
import { isOpenStore } from 'utils/storeStatus';
import { theme } from 'commons/style/theme';
import axios from 'axios';
import { Home, Payment, Person, Phone, WhatsApp } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputMaskComponent } from 'commons/input-mask-component';
import LocalMallIcon from '@mui/icons-material/LocalMall';

const Cart = () => {
  const { products, total, isOpen, openCart, closeCart } = useCart();
  const [nome, setNome] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>();
  const [error, setError] = useState<string | null>(null);
  const openStore = isOpenStore();
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    if (!nome || !phone || !address || !deliveryDate) {
      setError('Todos os campos são obrigatórios!');
      return false;
    }
    setError(null);
    return true;
  };

  const createPreference = async () => {
    try {
      const response = await axios.post(
        `
        ${process.env.REACT_APP_API_BACKEND}/public/create-preference/${process.env.REACT_APP_COMPANY_ID}`,
        {
          customer_name: nome,
          customer_phone: phone,
          customer_address: address,
          delivery_date: deliveryDate,
          Delivery: true, //TODO: Implementar quando o modal do Fillipi estiver pronto
          products: products.map((product) => ({
            product_id: product.id,
            item_name: product.title,
            quantity: product.quantity || 1,
            unit_price: product.price,
            subtype_uuid: product.selectedSubtype || '',
          })),
        }
      );

      if (response && response.data) {
        return response.data;
      } else {
        console.error('Error: Invalid response format');
        return null;
      }
    } catch (error) {
      console.error('Error creating Mercado Pago preference:', error);
      return null;
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSendToWhatsapp = (
    phone: string,
    address: string,
    deliveryDate: Dayjs | null | undefined
  ) => {
    const productsText = products
      .map(
        (product) =>
          `${product.title} | ${product.selectedSubtype} | Qtde: ${
            product.quantity || 1
          } | R$${product.price}`
      )
      .join('%0A');
    const totalPrice = products.reduce(
      (total, product) => total + product.price * (product.quantity || 1),
      0
    );

    const whatsappLink = `https://wa.me/${
      theme.numbers.whatsapp
    }/?text=Olá! Gostaria de fazer um pedido:%0A%0AProdutos:%0A%0A${productsText}%0A%0ATotal: ${totalPrice.toFixed(
      2
    )}%0A%0ATelefone: ${phone}%0AEndereço: ${address}%0AEntrega: ${dayjs(
      deliveryDate
    ).format('DD-MM-YYYY')} às ${dayjs(deliveryDate).format('HH:mm')}`;

    window.location.href = whatsappLink;
  };

  const handleToggleCart = (isOpen: boolean) => () =>
    isOpen ? closeCart() : openCart();

  return (
    <S.Container isOpen={isOpen}>
      <S.CartButton onClick={handleToggleCart(isOpen)} isOpen={isOpen}>
        {isOpen ? (
          <span>X</span>
        ) : (
          <S.CartIcon>
            <S.CartQuantity title="Quantidade de produtos no carrinho">
              {total.productQuantity}
            </S.CartQuantity>
          </S.CartIcon>
          // <Button onClick={() => openCart()} sx={{marginRight: 100}}>
          //   <LocalMallIcon
          //     fontSize="medium"
          //     sx={{ paddingLeft: 5, color: theme.colors.white }}
          //   />
          //   <Badge
          //     badgeContent={total.productQuantity}
          //     color="success"
          //     max={999}
          //     showZero
          //     sx={{ pt: 2, pr: 0.2 }}
          //   />
          // </Button>
        )}
      </S.CartButton>
      {isOpen && (
        <S.CartContent>
          <S.CartContentHeader>
            <S.CartIcon large isOpen>
              <S.CartQuantity>{total.productQuantity}</S.CartQuantity>
            </S.CartIcon>
            <S.HeaderTitle>Carrinho</S.HeaderTitle>
          </S.CartContentHeader>

          <CartProducts products={products} />

          <S.CartFooter>
            <S.Sub>SUBTOTAL</S.Sub>
            <S.SubPrice>
              <S.SubPriceValue>{`${total.currency_format} ${formatPrice(
                total.totalPrice,
                total.currency_id
              )}`}</S.SubPriceValue>
              <S.SubPriceInstallment>
                {total.installments ? (
                  <span>
                    {`OU EM ${total.installments} x ${
                      total.currency_format
                    } ${formatPrice(
                      total.totalPrice / total.installments,
                      total.currency_id
                    )}`}
                  </span>
                ) : null}
              </S.SubPriceInstallment>
            </S.SubPrice>

            <S.CheckoutButton autoFocus onClick={() => setIsModalVisible(true)}>
              <span style={{ textDecoration: 'none', color: '#fff' }}>
                Prosseguir
              </span>
            </S.CheckoutButton>
          </S.CartFooter>
        </S.CartContent>
      )}
      <Modal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            width: '600px',
            maxWidth: '100%',
          }}
        >
          <Typography variant="h6">
            {openStore ? 'Loja Aberta :)' : 'Aviso: Loja Fechada'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography>
            {openStore
              ? 'Mas antes, precisamos de alguns dados para a entrega ;)'
              : 'A loja está fechada no momento, o pedido será entregue no próximo dia útil. Mas antes, precisamos de alguns dados para a entrega :)'}
          </Typography>

          <Box component="form" sx={{ mt: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <TextField
                fullWidth
                label="Nome completo"
                variant="outlined"
                margin="normal"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <Person sx={{ color: theme.colors.primary }} />
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Telefone"
                variant="outlined"
                margin="normal"
                value={phone}
                onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                InputProps={{
                  inputComponent: InputMaskComponent,
                  startAdornment: (
                    <Phone sx={{ color: theme.colors.primary }} />
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Endereco completo"
                variant="outlined"
                margin="normal"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                InputProps={{
                  startAdornment: <Home sx={{ color: theme.colors.primary }} />,
                }}
              />
              <DateTimePicker
                label="Data de Entrega"
                minDateTime={dayjs(new Date()).toDate()}
                ampm={false}
                onChange={(newValue) => setDeliveryDate(dayjs(newValue))}
                sx={{
                  width: '100%',
                  mt: 2,
                  mb: 2,
                }}
              />
            </LocalizationProvider>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Divider sx={{ my: 2 }} />
          <Typography>
            Após preencher os dados, você será redirecionado para a finalização
            da venda.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button
              variant="outlined"
              startIcon={<WhatsApp />}
              style={{
                backgroundColor: theme.colors.quartiary,
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 10,
                fontSize: 12,
                height: 40,
                color: theme.colors.white,
              }}
              onClick={() => {
                if (validateForm()) {
                  handleSendToWhatsapp(phone, address, deliveryDate);
                }
              }}
            >
              Enviar para o WhatsApp
            </Button>
            <Button
              variant="outlined"
              startIcon={<Payment />}
              style={{
                backgroundColor: theme.colors.primary,
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 10,
                fontSize: 12,
                height: 40,
                color: theme.colors.white,
                marginLeft: 20,
              }}
              onClick={async () => {
                if (validateForm()) {
                  setLoading(true);
                  const checkoutUrl = await createPreference();
                  setLoading(false);
                  if (checkoutUrl) {
                    window.location.href = checkoutUrl;
                  }
                }
              }}
            >
              Pagar com Mercado Pago
            </Button>
          </Box>
        </Box>
      </Modal>
      {loading && (
        <Modal
          open
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              bgcolor: 'background.paper',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              boxShadow: 24,
              p: 4,
              width: '400px',
              maxWidth: '100%',
            }}
          >
            <CircularProgress sx={{ mr: 5 }} />
            <Typography variant="body1">
              Aguarde enquanto geramos o link de pagamento...
              <br /> Você será redirecionado em breve ;)
            </Typography>
          </Box>
        </Modal>
      )}
    </S.Container>
  );
};

export default Cart;
