import { useEffect } from 'react';

import Loader from 'components/Loader';
import Filter from 'components/Filter/Filter';
import Products from 'components/Products';
import Cart from 'components/Cart';
import FloatButtonService from 'components/FloatButtonService';
import ActionBar from 'components/ActionBar'
import CallToAction from 'components/CallToAction';
import Soluctions from 'components/Soluctions/Soluctions';
import Footer from 'components/Footer';
import Hero from 'components/Hero/Hero';

import { useProducts } from 'contexts/products-context';
import * as S from './style';
import { Container } from '@mui/material';

function App() {
  const { isFetching, products, fetchProducts } = useProducts();

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <S.Container>
      <Hero />
      {isFetching && <Loader />}
      <Soluctions />
            <Container>
      <S.TwoColumnGrid>
        <S.Side>
          <Filter />
        </S.Side>
        <S.Main>
          <S.MainHeader>
            <p>{products?.length} Produto(s) encontrados</p>

          </S.MainHeader>
          <Products products={products} />
        </S.Main>
      </S.TwoColumnGrid>
            </Container>
      <Cart />
      <ActionBar />
      <CallToAction />
      <FloatButtonService />
      <Footer />
    </S.Container>
  );
}

export default App;
