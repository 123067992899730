import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Menu,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getCategories } from 'services/categories';
import { ICategory } from 'models';
import { theme } from '../../../commons/style/theme';
import SellIcon from '@mui/icons-material/Sell';
import MenuIcon from '@mui/icons-material/Menu';

const MenuCategories: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (
    category: ICategory,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setSelectedCategory(category);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCategory(null);
  };

  const fakeProducts = [
    { name: 'Produto 1', price: 'R$ 100,00' },
    { name: 'Produto 2', price: 'R$ 200,00' },
    { name: 'Produto 3', price: 'R$ 300,00' },
  ];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      minWidth="100%"
      position="relative"
    >
      <Button
        variant="text"
        sx={{
          fontSize: '12px',
          fontFamily: 'Inter, Roboto, sans-serif',
          fontWeight: 'bold',
          color: theme.colors.white,
        }}
      >
        <MenuIcon sx={{ paddingRight: '5px' }} />
        Todas as categorias
      </Button>

      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        minWidth={'48vw'}
      >
        {categories.slice(0, 5).map((category) => (
          <Box key={category.id} ml={4}>
            <Button
              sx={{
                fontSize: '12px',
                fontFamily: 'Inter, Roboto, sans-serif',
                fontWeight: '500',
              }}
              variant="text"
              onClick={(event) => handleCategoryClick(category, event)}
            >
              {category.name} <ArrowDropDownIcon />
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedCategory?.id === category.id}
              onClose={handleClose}
              sx={{ mt: 2 }}
            >
              <Box display="flex" flexDirection="column" px={1}>
                {fakeProducts.map((product, index) => (
                  <Card
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '180px',
                      mb: 0.5,
                      height: 20,
                      padding: 2,
                      boxShadow: 3,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: '12px',
                          fontFamily: 'Inter, Roboto, sans-serif',
                        }}
                      >
                        {product.name}
                      </Typography>
                    </CardContent>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        fontFamily: 'Inter, Roboto, sans-serif',
                      }}
                    >
                      {product.price}
                    </Typography>
                  </Card>
                ))}
              </Box>
            </Menu>
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        right={0} 
      >
        <Button
          variant="text"
          sx={{
            fontSize: '12px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 'bold',
            color: theme.colors.white,
          }}
        >
          <SellIcon sx={{ paddingRight: '5px' }} />
          Ofertas
        </Button>
      </Box>
    </Box>
  );
};

export default MenuCategories;
